<template>
	<div>
		<!-- <h1 class="text-2xl font-bold mb-5">Business Loan Repayments</h1>

		<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for company's name etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div> -->
		<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Business Loan Repayments</h2>
      </div>
      <div class="col-span-1">
        <search-form
          v-model="searchQuery"
          class="w-full"
          placeholder="Search for company's name etc."
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

		<div
			class="border border-solid border-blue-200 rounded overflow-hidden"
		>
			<datatable
				:index="true"
				:reverse-index="true"
				:data="repayments"
				:columns="columns"
				:loading="loading"
				:query="searchQuery"
				ref="table"
			/>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			searchQuery: "",
			company: null,
			loading: false,
			repayments: [],
			columns: [
				{
					name: "company",
					th: "Company",
					render: (repayment) => repayment.company?.name,
				},
				{
					name: "amount",
					th: "Amount",
					render: (repayment, amount) =>
						`₦ ${this.$options.filters.currency(amount)}`,
				},
				{
					name: "status",
					th: "Status",
					render: (repayment) => {
						switch (repayment.status) {
							case "paid":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
							case "transferred":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Transferred
									</div>`;
							case "not_due":
								return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Not Due
									</div>`;
							case "due":
								return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Due
									</div>`;
							default:
								if (repayment.defaulted) {
									return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
											Default
										</div>`;
								}
								break;
						}
					},
				},
				{
					name: "due_date",
					th: "Due Date",
				},
			],
		};
	},
	beforeMount() {
		// this.getRepayments();
	},
	mounted() {},
	methods: {
		async getRepayments() {
			this.loading = true;
			await this.sendRequest("", {
				success: (response) => {
					this.repayments = response.data.repayments;
				},
			});
			this.loading = false;
		},
	},
};
</script>